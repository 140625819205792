<template>
  <div>
    <v-footer
    padless
    elevation="3"
    >
      <v-card
        tile
        width="100%"
        color="#1a3057"
        class="adam-footer"
      >
        <v-card-text>
          <v-container>
            <v-row justify="space-between">
              <v-col cols="12" sm="5" class="text-center">
                <p class="headline grey--text">Adam Travel Services</p>
                <p class="subtitle-1 grey--text">YOUR TRAVEL PARTNER FOR OVER 35 YEARS</p>
                <p class="body-2 mt-5 grey--text">
                  Adam Travel is one of the nation's largest consolidators offering a variety of products and services for all of your travel needs.
                </p>
                <p class="body-2 mt-5 grey--text">
                  In addition to airfares, Adam Travel is a large tour provider offering vacation packages across the globe as well as Hajj and Umrah packages.
                </p>
              </v-col>
              <v-divider vertical style="background-color: grey;"></v-divider>
              <v-col cols="12" sm="5" class="text-center">
                <a href="https://adamtravel.com/flight-club/">
                  <v-img src="../static/adam/logo-footer.jpg" max-width="50%" class="my-5 mx-auto"></v-img>
                </a>
                <p class="display-1 mt-5 white--text font-weight-bold">JOIN US SAVE BIG</p>
                <router-link class="white--text mx-1" to="/terms">Terms & conditions</router-link>
                <router-link class="white--text mx-1" to="/privacy">Privacy Policy</router-link>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <hr style="background-color: grey; border: none; height: 0.5px; margin: 20px">
        <v-card-actions>
          <v-container>
            <v-row justify="space-between" class="my-5">
              <div class="social-adam-cont">
                <a href="https://www.facebook.com/AdamTravelServices/" target="_blank"><div class="social-adam"><span>f</span></div></a>
                <a href="https://twitter.com/adamtravelusa" target="_blank"><div class="social-adam"><v-icon class="mt-1" color="#1a3057">mdi-twitter</v-icon></div></a>
                <a href="https://www.instagram.com/adamtravelusa/" target="_blank"><div class="social-adam"><v-icon class="mt-1" color="#1a3057">mdi-instagram</v-icon></div></a>
              </div>
              <div class="supp-adam-cont">
                <img src="../static/adam/discover.jpg" height="40px" class="mr-5">
                <img src="../static/adam/express.jpg" height="40px" class="mr-5">
                <img src="../static/adam/master.jpg" height="40px" class="mr-5">
                <img src="../static/adam/visa.jpg" height="40px" class="mr-5">
                <img src="../static/adam/arc.jpg" height="40px" class="mr-5">
                <img src="../static/adam/iatan.jpg" height="40px" class="mr-5">
              </div>
              <p class="caption grey--text text-right mt-3">
                &copy; {{ new Date().getFullYear() }} Adam Travel Services Inc All Right Reserved.
              </p>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      dialog1: false,
      title: '',
      social: [
        { icon: 'mdi-facebook', link: 'https://www.facebook.com/Fare33TravelServices', color: '#1877f2' },
        { icon: 'mdi-twitter', link: 'https://twitter.com/fare33_info', color: '#1da1f2' },
        { icon: 'mdi-instagram', link: 'https://www.instagram.com/fare33.info/', color: '#c32aa3' }
      ]
    }
  },
  methods: {
    goTo (page) {
      this.$router.push({ name: page })
    }
  }
}
</script>

<style scoped>
  .footer {
    background-size: contain;
    background-position: center;
  }
  .download-link {
    text-decoration: none;
    font-weight: 700;
    font-size: 1rem;
  }
  .details {
    font-size: 11px;
    margin-right: 15px;
  }
  h3 {
    font-size: 15px;
  }
  ul {
    list-style: none;
  }
  .social-links {
    text-decoration: none;
  }
  .list-items {
    text-decoration: none;
    color: grey;
    font-size: 12px;
    transition: .2s linear;
  }
  .list-items:hover {
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
  .subscripe {
    min-height: 220px;
    position: relative;
  }
  .btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .divider {
    background-color:#fff;
    width: 50px;
    height: 3px;
    margin-top: 5px;
    border: none;
  }
  .privacy-link {
    color: grey;
    text-decoration: none;
  }
  .privacy-link:hover {
    cursor: pointer;
    color: white;
  }
  .adam-footer {
    border-top: 3px solid #FDB71A !important;
  }
  .social-adam-cont {
    display: flex;
    margin-top: 5px;
  }
  .social-adam {
    background-color: #FDB71A;
    height: 30px;
    width: 30px;
    margin-right: 5px;
    text-align: center;
  }
  .social-adam:hover {
    background-color: #f1ad1a;
  }
  .social-adam span {
    color: #1a3057;
    font-size: 1.3rem;
    font-weight: 900;
  }
  .social-adam-cont a {
    text-decoration: none;
  }
  @media screen and (max-width: 960px) {
    .brand {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .details {
      margin-top: 50px;
      text-align: center;
    }
    ul {
      text-align: center;
      margin: 20px;
    }
  }
  @media screen and (max-width: 526px) {
    ul {
     list-style: disc;
    }
  }
  .links-list {
    list-style: none;
    margin-top: 20px;
    padding-left: 0px;
  }
  .links-list li {
    margin: 10px 0;
    text-align: left;
  }
  .links-list li a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 18px;
  }
</style>
