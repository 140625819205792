<template>
  <div>
    <v-app-bar
    dark
    color="white"
    elevation="0"
    prominent
    style="height: 80px;"
    scroll-off-screen
    >
      <v-container :fluid="$vuetify.breakpoint.mdAndDown">
        <v-row align="center">
            <v-btn icon @click="drawer = !drawer"
            class="d-flex d-md-none"
            >
              <v-icon large color="secondary">mdi-menu</v-icon>
            </v-btn>
            <v-col cols="2" class="pb-4">
              <router-link style="text-decoration: none;" to="/">
                <v-img src="../static/logo-adam.png" class="" alt="adam logo" min-width="110" max-width="130"></v-img>
              </router-link>
            </v-col>

            <div
            class="d-flex"
            >
              <v-btn
              v-for="tab in tabs"
              :key="tab.title"
              exact
              :to="{ name: tab.route} "
              :text="$route.name !== tab.title"
              :elevation="$route.name == tab.title ? 2 : 0"
              :tile="$route.name == tab.title" :color="$route.name == tab.title ? 'white' : 'secondary'"
              :height="85"
              :large="$vuetify.breakpoint.lgAndUp"
              class="d-none d-md-flex"
              >
                <span class="body-1 ml-2 font-weight-medium blueDark--text">{{tab.title}}</span>
              </v-btn>
            </div>

            <v-menu offset-y open-on-hover transition="slide-x-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="blueDark"
                  height="53"
                  class="d-none d-xl-flex"
                  :large="$vuetify.breakpoint.lgAndUp"
                  v-bind="attrs"
                  v-on="on"
                  text
                >
                  <span class="body-1">UMRAH</span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-btn class="d-none d-md-flex mt-3" color="blueDark" @click="goTo('umrah-pre')" text><span class="body-1">Umrah 2022 pre registeration</span></v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn class="d-none d-md-flex mt-3" color="blueDark" @click="goTo('umrah')" text><span class="body-1">Umrah 2022</span></v-btn>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu offset-y open-on-hover transition="slide-x-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="blueDark"
                  height="53"
                  class="d-none d-xl-flex"
                  :large="$vuetify.breakpoint.lgAndUp"
                  v-bind="attrs"
                  v-on="on"
                  text
                >
                  <span class="body-1">Hajj</span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-btn class="d-none d-md-flex mt-3" color="blueDark" @click="goTo('hajj-pre')" text><span class="body-1">hajj 2022 pre registeration</span></v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn class="d-none d-md-flex mt-3" color="blueDark" @click="goTo('hajj')" text><span class="body-1">hajj 2022</span></v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn class="d-none d-md-flex mt-3" color="blueDark" @click="goTo('hajj-blog')" text><span class="body-1">hajj blog</span></v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn class="d-none d-md-flex mt-3" color="blueDark" @click="goTo('hajj-resources')" text><span class="body-1">hajj resources</span></v-btn>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn class="d-none d-xl-flex" color="blueDark" @click="goTo('offices')" height="53" elevation="0" text :large="$vuetify.breakpoint.lgAndUp"><span class="body-1">Offices</span></v-btn>
            <v-btn class="d-none d-md-flex" color="blueDark" @click="goTo('about-adam')" height="53" elevation="0" text :large="$vuetify.breakpoint.lgAndUp"><span class="body-1">About us</span></v-btn>
            <v-btn class="d-none d-md-flex" color="blueDark" @click="goTo('contact-adam')" height="53" elevation="0" text :large="$vuetify.breakpoint.lgAndUp"><span class="body-1">Contact</span></v-btn>

            <v-spacer></v-spacer>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                class="py-2 d-none d-md-block"
                color="orange"
                dark
                v-bind="attrs"
                outlined
                large
                v-on="on"
                >
                  <v-icon left>mdi-cash</v-icon> {{currency.code}}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                v-for="(item, i) in currencies"
                :key="i"
                @click="$store.dispatch('setCurrency', item)"
                >
                  <v-list-item-title>{{ item.code }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <router-link class="text-decoration-none d-none d-sm-flex" :to="{ name: 'login'} " v-if="!$cookies.isKey('userToken')">
              <v-btn
              elevation="0"
              :large="$vuetify.breakpoint.lgAndUp"
              :small="$vuetify.breakpoint.mdAndDown"
              color="secondary"
              height="47"
              class="px-10 ml-1"
              >
                <span>Login</span>
                <v-icon right>mdi-login-variant</v-icon>
              </v-btn>
            </router-link>

            <v-menu offset-y v-else class="d-none d-sm-flex">
              <template v-slot:activator="{ on }">
                <v-btn
                color="white --text"
                text
                v-on="on"
                >
                  <v-avatar color="secondary" class="mr-2" size="40">
                    <span class="white--text body-1">
                      {{user.name.charAt(0).toUpperCase()}}
                    </span>
                  </v-avatar>
                  <span class="d-none d-sm-flex secondary--text">{{user.name.substr(0, user.name.indexOf(' '))}}</span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item link exact :to="{ name: 'profile' }">
                  <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
                  <v-list-item-title>Profile</v-list-item-title>
                </v-list-item>
                <v-list-item @click="logout">
                  <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
        </v-row>
      </v-container>

    </v-app-bar>

    <v-navigation-drawer
    v-model="drawer"
    absolute
    color="white"
    dark
    temporary
    >
      <v-list
      nav
      dense
      >
        <v-list-item-group
        v-model="group"
        active-class="secondary--text text-accent-4"
        >
          <v-list-item link exact to="/">
            <v-img src="../static/logo-adam.png" alt="adam logo" max-width="50px"></v-img>
          </v-list-item>

          <v-list-item two-line link exact :to="{ name: 'login' }" v-if="!$cookies.isKey('userToken')">
            <v-list-item-avatar>
              <v-icon color="secondary">mdi-account-circle</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="secondary--text">Login / Register</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line link exact :to="{ name: 'profile' }" v-else>
            <v-list-item-avatar v-if="user !== null">
              <v-avatar color="secondary" class="text-center" size="32">

                  <span class="white--text body-1">
                    {{user.name.charAt(0).toUpperCase()}}
                  </span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>

              <v-list-item-title v-cloak class="secondary--text">{{user.name}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item active-class="active-route-blue" link exact :to="{ name: item.route }" v-for="item in tabs" :key="item.title">
              <v-list-item-title :class="$route.name === item.title ? 'white--text' : 'secondary--text'">
                <v-icon small :color="$route.name === item.title ? 'white' : 'secondary'">{{item.icon}}</v-icon>
                {{item.title}}
              </v-list-item-title>
          </v-list-item>

          <v-list-group
          class="secondary--text"
          :value="true"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="secondary--text">Hajj</v-list-item-title>
              </v-list-item-content>
            </template>

              <v-list-item>
                <v-btn @click="goTo('hajj-pre')" text x-small color="secondary">hajj 2021 pre registeration</v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn @click="goTo('hajj')" text x-small color="secondary">hajj 2021</v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn @click="goTo('hajj-blog')" text x-small color="secondary">hajj blog</v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn @click="goTo('hajj-resources')" text x-small color="secondary">hajj resources</v-btn>
              </v-list-item>
          </v-list-group>
          <v-list-group
          :value="true"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="secondary--text">Umrah</v-list-item-title>
              </v-list-item-content>
            </template>

              <v-list-item>
                <v-btn @click="goTo('umrah-pre')" text x-small color="secondary">umrah 2021 pre registeration</v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn @click="goTo('umrah')" text x-small color="secondary">umrah 2021</v-btn>
              </v-list-item>
          </v-list-group>
          <v-list-item>
            <v-btn @click="goTo('offices')" class="mr-5" text small color="secondary">Offices</v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn @click="goTo('about-adam')" text small color="secondary">About Us</v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn @click="goTo('contact-adam')" text small color="secondary">Contact</v-btn>
          </v-list-item>

          <v-list-item>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                class="py-2"
                color="orange"
                dark
                v-bind="attrs"
                outlined
                large
                v-on="on"
                >
                  <v-icon left>mdi-cash</v-icon> {{currency.code}}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                v-for="(item, i) in currencies"
                :key="i"
                @click="$store.dispatch('setCurrency', item)"
                >
                  <v-list-item-title>{{ item.code }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { logout, headers } from '../links'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      drawer: false,
      group: null,
      tabs: [
        { title: 'flights', route: 'flights', icon: 'mdi-airplane' },
        // { title: 'hotels', route: 'hotels', icon: 'mdi-domain' },
        { title: 'cars', route: 'cars', icon: 'mdi-car' }
      ],
      links: [
        { title: 'home', route: 'Home' },
        { title: 'trips', route: 'trips' },
        { title: 'about us', route: 'about' },
        { title: 'rewards', route: 'rewards' }
      ],
      user: null
    }
  },
  computed: {
    userStore () {
      return this.$store.state.user
    },
    ...mapState(['currency', 'currencies'])
  },
  watch: {
    userStore (newVal) {
      this.user = newVal
    },
    group () {
      this.drawer = false
    }
  },
  methods: {
    goTo (link) {
      let baseUrl
      switch (link) {
        case 'about-adam':
          baseUrl = 'https://adamtravel.com/about-adam-travel/'
          window.open(baseUrl, '_blank')
          break
        case 'contact-adam':
          baseUrl = 'https://adamtravel.com/contact/'
          window.open(baseUrl, '_blank')
          break
        case 'offices':
          baseUrl = 'https://adamtravel.com/offices/'
          window.open(baseUrl, '_blank')
          break
        case 'umrah-pre':
          baseUrl = 'https://adamtravel.com/umrah-registration/'
          window.open(baseUrl, '_blank')
          break
        case 'umrah':
          baseUrl = 'https://adamtravel.com/package_cat/umrah2021/'
          window.open(baseUrl, '_blank')
          break
        case 'hajj-pre':
          baseUrl = 'https://adamtravel.com/hajj-2021/'
          window.open(baseUrl, '_blank')
          break
        case 'hajj':
          baseUrl = 'https://adamtravel.com/hajj-2/'
          window.open(baseUrl, '_blank')
          break
        case 'hajj-blog':
          baseUrl = 'https://adamtravel.com/hajj-blog/'
          window.open(baseUrl, '_blank')
          break
        case 'hajj-resources':
          baseUrl = 'http://hajjresources.adamtravel.com/'
          window.open(baseUrl, '_blank')
          break
        default:
          this.$router.push({ name: link })
          break
      }
    },
    logout () {
      this.$http.post(logout, null, { headers: headers(this.$cookies.get('userToken')) }).then(() => {
        this.$store.dispatch('removeUser')
        this.$cookies.remove('userToken')
        localStorage.removeItem('userId')
        localStorage.removeItem('token')
        this.$router.push({ name: 'login' })
      })
    }
  },
  created () {
    this.user = this.$store.state.user
  }
}
</script>

<style>
  [v-cloak] { display: none; }
  .subNav {
    overflow-x: auto;
  }
  /* .v-toolbar {
    height: 143px !important;
  } */
  .navinfo {
    background-color: #325dbe;
  }
  .v-toolbar__content {
    height: 95px !important;
  }
  .v-toolbar__extension {
    background-color: #325AAF;
  }
  .active-route {
    background-color: #E28134;
    border-radius: 5PX;
    height: 58px;
  }
  .active-route-blue {
    background-color: #150958;
    border-radius: 5PX;
    height: 58px;
  }
</style>
